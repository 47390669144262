import React from "react"
import InnerHeader from "../../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import * as employmentVerification from "./employee-verification.scss"

const ForOurApplicants = ({ pageContext, location }) => {
  const pageTitle = "Employee or Income Verification"

  return (
    <Layout>
      <Seo title={pageTitle} />
      <InnerHeader
        pageTitle={pageTitle}
        pageContext={pageContext}
        location={location}
      />
      <div className="inner-container-alt inner-y-padding">
        <h1>Employee Verification</h1>
        
        <ul className={employmentVerification.list}>
          <li>
            <a
              href="../../docs/employee-verification/vault-verify.pdf"
              title="Employee or Income Verification"
              className="biggerLink"
              target="_blank"
            >
              Employee or Income Verification 
            </a>
          </li>
        </ul>
        

        <p><a class="CTAblack" href="https://recruiting.ultipro.com/ROC1011RBD/JobBoard/7e2cec98-83e5-418d-9e0e-1a03210a5c51/?q=&o=postedDateDesc&w=&wc=&we=&wpst=" title="View Open Positions">View Open Positions</a></p>
      </div>
    </Layout>
  )
}

export default ForOurApplicants
